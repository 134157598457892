<template>
  <v-footer color="red" padless>
    <v-row justify="center" no-gutters>
      <v-btn v-for="link in links"
       :key="link.text"
       :to="link.to"
        color="white" text rounded class="my-2">
        {{ link.text }}
      </v-btn>
      <v-col dark class="py-4 text-center white--text" cols="12">
        {{ new Date().getFullYear() }} — <strong>Ascosoftware</strong> in samenwerking met <span class="amelie"><a href="https://ameliewebdesign.nl"> Ameliewebdesign</a></span>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    links: [
      { text: 'Home', to: "/" },
      { text: 'Over ons', to: "/Overons" },
          { text: 'Help', to: "/Help" },
      { text: 'Contact', to: "/Contact" },
  
    ],
  }),
}
</script>
<style scoped>
.amelie a{
  text-decoration: none;
  color:white;
  font-weight: bold;
}
</style>