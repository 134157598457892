import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase';
import axios from 'axios';

Vue.use(VueRouter);
const isLoggedIn = () => firebase.auth().currentUser;

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    meta: {
      title: ' KantooorFlex | Huren van een kantoorRuimte in Nederland',
    },
  },
  {
    path: '/Overons',
    name: 'Overons',
    component: () =>
      import(/* webpackChunkName: "Overons" */ '../views/Overons.vue'),
    meta: {
      title: ' Flexplek huren verhuren | Flexibele kantoorruimte | Kantoorflex',
    },
  },

  {
    path: '/MakelaarsSoftware',
    name: 'MakelaarsSoftware',
    component: () =>
      import(
        /* webpackChunkName: "MakelaarsSoftware" */ '../views/MakelaarsSoftware.vue'
      ),
    meta: {
      title: ' Flexplek huren verhuren | waarom kantoorflex | Kantoorflex',
    },
  },

  {
    path: '/KassaSoftware',
    name: 'KassaSoftware',
    component: () =>
      import(
        /* webpackChunkName: "MakelaarsSoftware" */ '../views/KassaSoftware.vue'
      ),
    meta: {
      title: ' Kassa Software | AScosoftware',
    },
  },
  
  {
    path: '/Wordpress-plug-ins',
    name: 'Wordpress-plug-ins',
    component: () =>
      import(
        /* webpackChunkName: "MakelaarsSoftware" */ '../views/Wordpress-plug-ins.vue'
      ),
    meta: {
      title: ' Flexplek huren verhuren | waarom kantoorflex | Kantoorflex',
    },
  },


  {
    path: '/Help',
    name: 'Help',
    component: () =>
      import(
        /* webpackChunkName: "MakelaarsSoftware" */ '../views/Help.vue'
      ),
    meta: {
      title: ' Flexplek huren verhuren | Help kantoorflex | Kantoorflex',
    },
  },


  {
    path: '/Contact',
    name: 'Contact',
    component: () =>
      import(/* webpackChunkName: "Overons" */ '../views/Contact.vue'),
    meta: {
      title: ' Flexplek huren verhuren | Contact opnemen | Kantoorflex',
    },
  },




  {
    path: '/Privacyvoorwaarden',
    name: 'Privacyvoorwaarden',
    component: () =>
      import(
        /* webpackChunkName: "Privacy" */ '../views/Privacyvoorwaarden.vue'
      ),
    meta: {
      title: ' Flexplek huren verhuren | Flexibele kantoorruimte | Kantoorflex',
    },
  },

  {
    path: '/Algemenevoorwaarden',
    name: '/Algemenevoorwaarden',
    component: () =>
      import(
        /* webpackChunkName: "Privacy" */ '../views//Algemenevoorwaarden.vue'
      ),
    meta: {
      title:
        ' Flexplek/Algemenevoorwaarden| Flexibele kantoorruimte | Kantoorflex',
    },
  },


  {
    path: '*',
    name: 'NotFound',
    component: () =>
      import(/* webpackChunkName: "NotFound" */ '@/views/404.vue'),
    meta: { title: ' Kantoorflex | Oeps pagina niet gevonden' },
  },
];

export async function setFirebaseToken() {
  const token = (await isLoggedIn())
    ? await firebase.auth().currentUser.getIdToken(true)
    : null;
  if (token) axios.defaults.headers.common['fireToken'] = token;
}

async function onAuthRequired(to, from, next) {
  document.title = to.meta.title || 'Kantoorflex | Flexplekken in Nederland';
  await setFirebaseToken();
  if (to.meta.requiresAuth) {
    if (!isLoggedIn()) {
      next({
        path: '/Inloggen',
        query: { redirect: to.fullPath }
      })
    }
    const response = await axios.get(
      `https://server.kantoorflex.nl/api/whoami`
    );
    const accessRight = response.data.accessRight;
    if (to.meta.accessRights && !to.meta.accessRights.includes(accessRight)){
      if (["MAKELAAR", "WERKNEMER"].includes(accessRight)) {
        next("/Dashboard")
      } else if (["CUSTOMER"].includes(accessRight)) {
        next("/KlantPortaal")
      }
     else if (["ADMIN"].includes(accessRight)) {
      next("/AdminPortaal")
    }else {
        next("Inloggen")
      }
      return;
    }

  }
  next();
}
const router = new VueRouter({
  mode: 'history',
  //Dit voor weer naar begin
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(onAuthRequired);
export default router;
